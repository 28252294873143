import PropTypes from "prop-types"
import React from "react"
import * as R from "ramda"

import urlForContentType from "../../../data/url-for-content-type.json"

import { Container, Grid } from "@material-ui/core"
import ArticleLift from "../ArticleLift"

const FeaturedContent = ({ items }) => {
  return items.map((item, idx) => {
    // odd==true / even==false
    const align = !!(idx % 2) ? "left" : "right"

    return (
      <Container key={idx}>
        <Grid container>
          <Grid item xs={12}>
            <ArticleLift
              size="big"
              align={align}
              image={item.headingImage}
              heading={item.heading ? item.heading : item.title}
              text={R.path(["leadText", "leadText"], item)}
              urlPath={
                urlForContentType[
                  R.path(["sys", "contentType", "sys", "id"], item)
                ]
              }
              slug={item.slug}
            />
          </Grid>
        </Grid>
      </Container>
    )
  })
}

FeaturedContent.propTypes = {
  items: PropTypes.array,
}

FeaturedContent.defaultProps = {
  items: [],
}

export default FeaturedContent
